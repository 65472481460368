import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import SmallHero from '../components/elements/small-hero/small-hero';
import BookCta from '../components/elements/book-cta/book-cta';

import mailIcon from '../images/mail.svg';
import phoneIcon from '../images/phone.svg';

const ContactPage = ({ data }) => {

  let [loaded, setLoaded] = useState(true)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Layout>
      <SEO
        keywords={[`Contact`]}
        title="Contact"
      />
      <SmallHero 
        marginBottom
        loaded={loaded}
        bgImage={data.herofull.childImageSharp.fluid}
        title='Contact'
      />
      <div className="flex flex-wrap mx-auto max-w-screen-xl mb-10 md:mb-32 px-4 justify-between">
        <div className="w-full mb-10 lg:mb-0 lg:w-2/5">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2434.4554082385303!2d0.2641241158029285!3d52.398422079791196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8130b50e016a9%3A0x5ba3510c57407e53!2sPure%20Ely%20Ltd!5e0!3m2!1sen!2suk!4v1597224359293!5m2!1sen!2suk" width="100%" height="560" frameBorder="0"  allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 mb-10 lg:mb-0 ">
          <h3 className="text-primary text-4xl uppercase font-light leading-tight mb-4">Contact Information</h3>
          <p className="text-primary-dark mb-4"> 5 Buttermarket, <br/>
          Ely, Cambs, <br/>
          CB7 4NY  </p>
          <a className="mb-3 block" href="mailto:elybeautyandskinclinic@gmail.com"><div className="flex flex-wrap text-primary-dark items-start"><img className="pr-2 w-8" src={mailIcon} />elybeautyandskinclinic@gmail.com</div></a>
          <a className="mb-10 block" href="tel:01353661321"><div className="flex flex-wrap text-primary-dark items-start"><img className="pr-2 w-8" src={phoneIcon} />01353 661 321</div></a>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 mb-10 lg:mb-0 leading-relaxed">
          <h3 className="text-primary text-4xl uppercase font-light leading-tight mb-4">OPENING HOURS</h3>
          <p className="text-primary-dark">
            <b>Mon:</b> 9am to 6pm <br/>
            <b>Tue:</b> 9am to 8pm <br/>
            <b>Wednesday:</b> 9am to 6pm <br/>
            <b>Thu:</b> 9am to 5pm <br/>
            <b>Fri:</b> 9am to 5pm <br/>
            <b>Sat:</b> 9am to 3pm <br/>
            <b>Sun:</b> Closed
          </p>
        </div>
      </div>
      <div className="flex flex-wrap mx-auto max-w-screen-xl mb-10 md:mb-32 justify-between">
        <h3 className="text-primary text-4xl uppercase font-light leading-tight mb-4 px-4">GET IN TOUCH</h3>
        <form name="contact" method="POST" action="https://formspree.io/f/xpzodqoe" className="flex flex-wrap text-white">
          <div className="w-full md:w-1/2 px-4 md:pr-2 mb-4">
            <input type="text" name="firstname" className="w-full bg-primary placeholder-opacity-75 placeholder-white p-4" placeholder="first name" />
          </div>
          <div className="w-full md:w-1/2 px-4 md:pl-2 mb-4">
            <input type="text" name="lastname" className="w-full bg-primary placeholder-opacity-75 placeholder-white p-4" placeholder="last name" />
          </div>
          <div className="w-full md:w-1/2 px-4 md:pr-2 mb-4">
            <input type="email" name="email" className="w-full bg-primary placeholder-opacity-75 placeholder-white p-4" placeholder="email" />
          </div>
          <div className="w-full md:w-1/2 px-4 md:pl-2 mb-4">
            <input type="tel" name="phone" className="w-full bg-primary placeholder-opacity-75 placeholder-white p-4" placeholder="phone" />
          </div>
          <div className="w-full px-4 mb-4">
            <textarea type="text" name="message" rows="6" className="w-full bg-primary placeholder-opacity-75 placeholder-white p-4" placeholder="message">
            </textarea>
          </div>
          <div className="w-full px-4">
            <button type="submit" className="bg-primary-dark text-white py-4 px-8 hover:bg-primary-darker duration-300">Submit</button>
          </div>
          <div className="g-recaptcha" data-sitekey="6LfPRN4ZAAAAAN1btEL11494zJ5OFWV8JWXyRrAR"></div>
        </form>
      </div>
      <BookCta discount="https://gift-cards.phorest.com/salons/houseofpureltd" link="https://phorest.com/book/salons/houseofpureltd"  phone="01353 661 321" email="elybeautyandskinclinic@gmail.com" bg={data.bookbg.childImageSharp.fluid} />
    </Layout>
  );
}

ContactPage.propTypes = {
  data: PropTypes.object,
};

const indexQuery = graphql`
  query {
    herofull: file(relativePath: { eq: "main-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookbg: file(relativePath: { eq: "booking-bg-blurry.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const ContactComponent = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ContactPage props data={data} {...props} />
    )}
  />
)

ContactComponent.displayName = "ContactComponent"

export default ContactComponent